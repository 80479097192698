<template>
  <div class="home">
    <br />

    <a v-if="isMinister" class="nav-link" @click="goPage('pastorSogamList')">
      <div class="sb-nav-link-icon">
        <i class="fas fa-tachometer-alt"></i>
      </div>
      소감리스트(목사님전용)
    </a>
    <a v-else class="nav-link" @click="goPage('sogamList')">
      <div class="sb-nav-link-icon">
        <i class="fas fa-tachometer-alt"></i>
      </div>
      소감리스트
    </a>

    <a class="nav-link" @click="goPage('sogamWrite')">
      <div class="sb-nav-link-icon">
        <i class="fas fa-tachometer-alt"></i>
      </div>
      소감쓰기
    </a>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      userId: '',
      userMemId: '',
      userCell: '',
      userTeam: '',
      userName: '',
      concertCd: '',
      concertNm: '',
      judgeYn: 'N'
    }
  },
  async mounted() {
    // alert('userId===>' + this.$route.params.userId)
    const self = this
    console.log('HomeView mounted !!!')
    console.log(this.$route.query)

    const form = {
      encStr: this.$route.query.encStr,
      ip: this.$route.query.ip,
      sp: this.$route.query.sp
    }

    await axios
      .post('/api/decodeM', form)
      .then(function (response) {
        console.log(response)
        // self.$router.push({ path: '/' })
        self.userId = response.data.userId
        self.userMemId = response.data.userMemId
        self.userCell = response.data.userCell
        self.userTeam = response.data.userTeam
        self.userName = response.data.userName
        console.log(self.userId)
        console.log(self.userMemId)
        console.log(self.userCell)
        console.log(self.userTeam)
        console.log(self.userName)

        // store에 저장.
        self.setStoreData1(response.data)

        // self.goList()
      })
      .catch(function (error) {
        console.log(error)
        self.setStoreForErr()
      })
  },
  methods: {
    async setStoreData1(data) {
      const self = this

      const myDate = new Date()
      const yy = String(myDate.getFullYear())
      const form1 = {
        year: yy
      }

      await axios
        .post('/api/christmas/info', form1)
        .then(function (response) {
          console.log(response)
          // self.$router.push({ path: '/' })
          self.concertCd = response.data.cInfo.concertCd
          self.concertNm = response.data.cInfo.concertNm
          console.log(self.concertCd)
          console.log(self.concertNm)

          // store에 저장.
          self.setStoreData2(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })

      const form2 = {
        concertCd: this.concertCd,
        hompyId: this.userId
      }

      await axios
        .post('/api/christmas/judge', form2)
        .then(function (response) {
          self.setStoreData3(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })

      setTimeout(() => {
        this.$store.state.userId = data.userId
        this.$store.state.userMemId = data.userMemId
        this.$store.state.userCell = data.userCell
        this.$store.state.userTeam = data.userTeam
        this.$store.commit('setUserName', data.userName)
      }, 200)
    },
    setStoreData2(data) {
      this.$store.state.concertCd = data.cInfo.concertCd
      this.$store.state.concertNm = data.cInfo.concertNm
      if (data.cInfo.openYn === 'Y') {
        this.$store.state.isChristMasSeason = true
        console.log(
          'isChristMasSeason ::' + this.$store.state.isChristMasSeason
        )
      } else {
        this.$store.state.isChristMasSeason = false
        console.log(
          'isChristMasSeason ::' + this.$store.state.isChristMasSeason
        )
      }
    },
    setStoreData3(data) {
      if (data.judgeInfo.judgeType === '1') {
        this.$store.state.judgeYn = 'Y'
        this.$store.state.isXmasAdmin = false
      } else if (data.judgeInfo.judgeType === '6') {
        this.$store.state.judgeYn = 'N'
        this.$store.state.isXmasAdmin = true
      } else {
        this.$store.state.judgeYn = 'N'
        this.$store.state.isXmasAdmin = false
      }
      this.$store.state.partCd = data.judgeInfo.partCd
      this.$store.state.partNm = data.judgeInfo.partNm

      console.log('judgeType ::' + data.judgeInfo.judgeType)
      console.log('judgeYn11 ::' + this.$store.state.judgeYn)
      console.log('isXmasAdmin ::' + this.$store.state.isXmasAdmin)
    },
    setStoreForErr() {
      this.$store.state.userId = 'Err'
      this.$store.state.userMemId = 'Err'
      this.$store.state.userCell = 'Err'
      this.$store.state.userTeam = 'Err'
      this.$store.commit('setUserName', 'Err')
    },
    goPage(url) {
      if (this.$store.state.userId === 'Err') {
        alert('비정상적인 접속입니다.')
        return
      }
      this.$router.push({
        name: url
      })
    },
    goList() {
      this.$router.push({
        path: '/sogamList',
        query: { page: this.page }
      })
    }
  }
}
</script>
